import store from '@/store/index';

export default [
  {
    path: '/gallery',
    name: 'gallery',
    beforeEnter: (to, from, next) => {
      const isAdmin = store.getters['user/isAdmin'];
      const isClient = store.getters['user/isClient'];

      if (isAdmin || isClient) {
        next();
      } else {
        next({ name: 'orders' }); // Redirect unauthorized users to orders page
      }
    },
    component: () => import(/* webpackChunkName: "gallery" */ '@views/Gallery.vue')
  }
];
